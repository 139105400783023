import React from 'react'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Layout from '../components/layout'
import { useStaticQuery, graphql } from 'gatsby'
import SermonCard from '../components/sermonCard'
import Col from 'react-bootstrap/Col'
import image from '../assets/img/intro.jpg'




const LivestreamPage = () => {

  return (
    <>
      <Layout title={'Livestream'} subtitle={'HRBC'}>
        <section id="sermons" className="page-section cta">
          <Container>
            <Row>
              <Col xl={9} className="mx-auto">
                <div className="cta-inner text-center rounded">
                  <h2 className="section-heading mb-5">
                    <span className="section-heading-upper">Watch Sunday Services</span>
                    <span className="section-heading-lower">Live</span>
                  </h2>
                  {/* <iframe width="560" height="315" src="https://www.youtube.com/embed/tc1SLo8meqg" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe> */}
                  <div className="container-iframe">
                    <iframe className="responsive-iframe" src="https://www.youtube.com/embed/vFltQeaQNbo" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen="true"></iframe>
                  </div>
                  <div className="container-iframe">
                    <iframe className="responsive-iframe" src="https://www.youtube.com/live_chat?v=vFltQeaQNbo&amp;embed_domain=http://localhost:8000"></iframe>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </section>
      </Layout>
    </>
  )
}

export default LivestreamPage
